<template>
    <div>

        <!-- Banner -->
        <app-banner pageName="CPR Training Services" />

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>

                    <!--  -->
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Services' }" tag="a" property="item" typeof="WebPage" title="Go to Services." class="post post-page">
                                <span property="name">Services</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">CPR Training Services</span>
                    </div>

                    <!--  -->
                    <div id="post-50" class="post-50 page type-page status-publish hentry">
                        <div class="entry-content">
                            <div class=intro_txt>
                                Are you ready to make a difference in someone's life or does your job requires you to  get CPR certification?
                            </div>
                            <p>Join us at Successful Aging Career Institute for an essential CPR First Aid and AED training!</p>
                            <p>Every second counts in an emergency, and being equipped with CPR skills can be the difference between life and death.</p>
                            <p>
                                Did you know that cardiac arrest is a leading cause of death worldwide? With proper CPR training, you can become a life-saver in your community.
                                Whether you're a parent, a teacher, an athlete, or a healthcare professional, CPR certification is an essential skill for everyone.
                            </p>
                            <p>At Successful Aging Career Institute, we pride ourselves on our experienced and certified instructors who provide hands-on training to ensure you gain the confidence to act swiftly in emergencies.</p>

                            <!-- FORM HERE -->
                            <c-p-r-training-services-form />
                            <!-- END FORM -->

                        </div>
                    </div>

                </main>

                <div class="wrapper text-center" style="padding-bottom: 20px;">
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                    <div class="animate" data-animate="fadeInUp" data-duration="2091ms">
                        <img style="padding: 10px;" src="~@/assets/images/approved.png" alt="">
                        <img style="padding: 10px;" src="~@/assets/images/license.png" alt="">
                    </div>
                    <div class="animate" data-animate="fadeInUp" data-duration="1.0s"></div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/components/layouts/banner'
import CPRTrainingServicesForm from '@/components/forms/CPRTrainingServicesForm'

export default {
    components: {
        appBanner: banner,
        CPRTrainingServicesForm
    }
}
</script>
