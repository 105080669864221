<template>
    <div class="clearfix">
        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" @submit.prevent="handleSubmitForm" name="contact">

                            <alert :alertData="alertData"/>

                            <span class="required-info">* Required Information</span>

                            <!-- Applicant Information -->
                            <div class="main fieldbox">
                                <div class="fieldbox">
                                    <div class="fieldheader">
                                        <p style="position: relative; text-align: left;">
                                            <i class="fa fa-info-circle"></i> Applicant Information
                                            <span style="position: absolute; right: 20px;">Application Fee $90</span>
                                        </p>
                                        <input autocomplete="nope" type="hidden" name="Applicant_Information" value=":">
                                    </div>
                                    <div class="fieldcontent">
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="First_Name" class="text_uppercase">
                                                        <strong>First Name
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="First_Name" v-model="CPRTraining.firstName" :class="{ 'error': submitted && $v.CPRTraining.firstName.$error }" class="form_field" id="First_Name" placeholder="Enter first name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Last_Name" class="text_uppercase">
                                                        <strong>Last Name
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Last_Name" v-model="CPRTraining.lastName" :class="{ 'error': submitted && $v.CPRTraining.lastName.$error }" class="form_field" id="Last_Name" placeholder="Enter last name here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Address" class="text_uppercase">
                                                        <strong>Address
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Address" v-model="CPRTraining.address" :class="{ 'error': submitted && $v.CPRTraining.address.$error }" class="form_field" id="Address" placeholder="Enter address here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Apartment_Number" class="text_uppercase">
                                                        <strong>Apartment Number
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Apartment_Number" v-model="CPRTraining.apartmentNumber" :class="{ 'error': submitted && $v.CPRTraining.apartmentNumber.$error }" class="form_field" id="Apartment_Number" placeholder="Enter here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_box_col3">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="City" class="text_uppercase">
                                                        <strong>City
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="City" v-model="CPRTraining.city" :class="{ 'error': submitted && $v.CPRTraining.city.$error }" class="form_field" id="City" placeholder="Enter city here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="State" class="text_uppercase">
                                                        <strong>State
                                                            <span class="required_filed"></span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="select-field">
                                                    <select name="State" id="State" v-model="CPRTraining.state" class="form_field">
                                                        <option value="">Please select state.</option>
                                                        <option value="Alabama">Alabama</option>
                                                        <option value="Alaska">Alaska</option>
                                                        <option value="Arizona">Arizona</option>
                                                        <option value="Arkansas">Arkansas</option>
                                                        <option value="California">California</option>
                                                        <option value="Colorado">Colorado</option>
                                                        <option value="Connecticut">Connecticut</option>
                                                        <option value="Delaware">Delaware</option>
                                                        <option value="District Of Columbia">District Of Columbia</option>
                                                        <option value="Florida">Florida</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Hawaii">Hawaii</option>
                                                        <option value="Idaho">Idaho</option>
                                                        <option value="Illinois">Illinois</option>
                                                        <option value="Indiana">Indiana</option>
                                                        <option value="Iowa">Iowa</option>
                                                        <option value="Kansas">Kansas</option>
                                                        <option value="Kentucky">Kentucky</option>
                                                        <option value="Louisiana">Louisiana</option>
                                                        <option value="Maine">Maine</option>
                                                        <option value="Maryland">Maryland</option>
                                                        <option value="Massachusetts">Massachusetts</option>
                                                        <option value="Michigan">Michigan</option>
                                                        <option value="Minnesota">Minnesota</option>
                                                        <option value="Mississippi">Mississippi</option>
                                                        <option value="Missouri">Missouri</option>
                                                        <option value="Montana">Montana</option>
                                                        <option value="Nebraska">Nebraska</option>
                                                        <option value="Nevada">Nevada</option>
                                                        <option value="New Hampshire">New Hampshire</option>
                                                        <option value="New Jersey">New Jersey</option>
                                                        <option value="New Mexico">New Mexico</option>
                                                        <option value="New York">New York</option>
                                                        <option value="North Carolina">North Carolina</option>
                                                        <option value="North Dakota">North Dakota</option>
                                                        <option value="Ohio">Ohio</option>
                                                        <option value="Oklahoma">Oklahoma</option>
                                                        <option value="Oregon">Oregon</option>
                                                        <option value="Pennsylvania">Pennsylvania</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Rhode Island">Rhode Island</option>
                                                        <option value="South Carolina">South Carolina</option>
                                                        <option value="South Dakota">South Dakota</option>
                                                        <option value="Tennessee">Tennessee</option>
                                                        <option value="Texas">Texas</option>
                                                        <option value="Utah">Utah</option>
                                                        <option value="Vermont">Vermont</option>
                                                        <option value="Virgin Islands">Virgin Islands</option>
                                                        <option value="Virginia">Virginia</option>
                                                        <option value="Washington">Washington</option>
                                                        <option value="West Virginia">West Virginia</option>
                                                        <option value="Wisconsin">Wisconsin</option>
                                                        <option value="Wyoming">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Zip" class="text_uppercase">
                                                        <strong>Zip
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Zip" v-model="CPRTraining.zipCode" :class="{ 'error': submitted && $v.CPRTraining.zipCode.$error }" class="form_field" id="Zip" placeholder="Enter zip here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_box_col2">
                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Phone" class="text_uppercase">
                                                        <strong>Phone
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <vue-tel-input name="Phone" v-model="CPRTraining.phoneNumber" :class="{ 'error': submitted && $v.CPRTraining.phoneNumber.$error }" class="form_field cphone" :inputOptions="{ id: 'Phone' }" placeholder="+1 (phone number)" />
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>

                                            <div class="group">
                                                <div class="form_label">
                                                    <label for="Email" class="text_uppercase">
                                                        <strong>Email
                                                            <span class="required_filed">*</span>
                                                        </strong>
                                                    </label>
                                                </div>
                                                <div class="field_holder animated_box">
                                                    <input autocomplete="nope" type="text" name="Email" v-model="CPRTraining.email" :class="{ 'error': submitted && $v.CPRTraining.email.$error }" class="form_field" id="Email" placeholder="Enter email here">
                                                    <span class="animated_class"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_box">
                                            <div class="form_box_col1">
                                                <div class="group">
                                                    <div class="form_label">
                                                        <label for="mce-referredBy" class="text_uppercase">
                                                            <strong>How did you hear about us?</strong>
                                                        </label>
                                                    </div>
                                                    <div class="field_holder animated_box">
                                                        <input
                                                            type="text"
                                                            v-model="CPRTraining.referredBy"
                                                            name="referredBy"
                                                            class="form_field"
                                                            autocomplete="nope"
                                                            placeholder="How did you hear about us?"
                                                            :class="{ error: submitted && $v.CPRTraining.referredBy.$error }"
                                                            id="mce-referredBy"
                                                        />
                                                        <span class="animated_class"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form_box5 secode_box text-center" style="margin-top: 10px;">
                                            <div class="inner_form_box1 recapBtn">
                                                <div class="btn-submit">
                                                    <button type="submit" class="button form_button" :disabled="loading">MAKE PAYMENT</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </form>

                        <vue-stripe-checkout
                            ref="checkoutRef"
                            name="Agingcare"
                            currency="USD"
                            :amount="amount * 100"
                            :allow-remember-me="true"
                            @done="submitPayment"
                        />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import alert from '@/components/layouts/alert'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'
import { postNewCPRTraining, stripeMakeCharge } from '@/api/apiCalls'

import VueStripeCheckout from 'vue-stripe-checkout'
Vue.use(VueStripeCheckout, 'pk_test_51I3ptUK3CSO6T7X0h45Z6CAt4y4fq3Av4LGkZLGoBLZzccdXD7eWQlCoNURZwV4E8KoOqxu0qJLSSKJSP8afYjcR00Y4YJE0yr')
// Vue.use(VueStripeCheckout, 'pk_live_51JmWMtGgfN6QolTHc2js0CcdsWJntcqHqb8CkrBFewKPdGRTRjNbcbQQUnVS7X5BHgKKErI6V6NByXdSEM1dz4IM00fmke9iYd')

export default {
    data() {
        return {
            crm: {},
            amount: 90,
            submitted: false,
            loading: false,
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!'
            },
            CPRTraining: {
                state: '',
            },
        }
    },
    validations: {
        CPRTraining: {
            firstName: { required },
            lastName: { required },
            address: { required },
            apartmentNumber: { required },
            city: { required },
            zipCode: { required },
            phoneNumber: { required },
            email: { required, email },
            referredBy: {},
        }
    },
    components: {
        alert
    },
    methods: {
        handleSubmitForm() {
            this.submitted = true

            // stop here if form is invalid
            this.$v.$touch()

            // Scroll To Error
            this.scrollToError()

            if (!this.$v.$invalid) {
                this.loading = true

                if (this.CPRTraining) {
                    this.CPRTraining.userAgent = getUserAgent()
                    this.CPRTraining.ip = getUserIp()
                }

                postNewCPRTraining({ ...this.CPRTraining }).then(({ data }) => {
                    this.$scrollTo('#contact_us_form_1', 100)
                    this.alertData.showAlert = true

                    // Change Action To Payment Page
                    if (data.data && data.data.hash) {
                        this.crm = data.data
                        // this.$router.push({
                        //     name: 'Payment',
                        //     params: {
                        //         hash: data.data.hash,
                        //     },
                        //     query: {
                        //         type: 'cpr-training-services'
                        //     }
                        // })

                        this.$refs.checkoutRef.open()
                    } else {
                        this.$router.push({
                            name: 'Confirmation'
                        })
                    }

                    this.CPRTraining = {
                        state: '',
                    }
                }).catch(({ response }) => {
                    this.alertData = {
                        showAlert: true,
                        alertType: 'danger',
                        alertText: response.data.message ? response.data.message : (response.data.errors.email && response.data.errors.email[0] ? response.data.errors.email[0] : 'Something went wrong, please try again later' )
                    }

                    this.scrollToError('#warning-box-container')
                }).finally(() => {
                    this.loading = false
                    this.submitted = false

                    setTimeout(() => {
                        this.alertData.showAlert = false
                    }, 3000)
                })
            }
        },

        submitPayment({ token }) {
            stripeMakeCharge({
                type: 'cpr-training-services',
                source: token.id,
				amount: this.amount * 100,
                token: token.id,
                clientIp: token.client_ip,
                created: token.created,
                email: token.email,
                cardId: token.card.id,
                last4: token.card.last4,
                brand: token.card.brand,
                card: token.card,
                hash: this.crm.hash,
                id: this.crm.id
            }).then(() => {
                this.$router.push({
                    name: 'Confirmation'
                })
            }).catch(({ response }) => {
                this.$swal('', response.data.message, 'error')
            })
		},
    }
}
</script>

<style>
body {
    font-family: 'Muli', sans-serif;
}
</style>

<style lang="scss" scoped>
.form_head {border-radius: 10px; }
.form_head p.title_head:nth-child(1) { background: #ff3f3f;  margin: 0;  padding: 10px;  color: #fff;  font-weight: bold;  border-top-right-radius: 8px;  border-top-left-radius: 8px;}
.form_head .form_box .form_box_col1 p { margin-bottom: 4px; }
.form_head .form_box { margin: 0; padding: 25px 28px; border: 2px solid #ddd; border-top: none;  border-bottom-right-radius: 8px;  border-bottom-left-radius: 8px;}
.fieldh{text-align: center;}
.amount{
    padding: 10px 90px;
}
#icon {
    position: absolute;
    padding: 10px 39px 10px 10px;
    background: #002948;
    // height: 61px;
    color: #fff;
    font-size: 31px;
    top: 0;
    bottom: 0;
    margin-top: 0!important;
}
.fa-dollar-sign::before {
    content: "\f155";
    position: relative;
    left: 13px;
    top: 5px;
}
.label {text-transform: uppercase;font-weight: bold;font-size: 20px;display: block;background: #1d58b3;padding: 20px 0;text-align: center;color: #fff;margin:5px 0;}
.sub_cont {padding: 14px;background: #eeeeee;font-size: 20px;line-height: 29px;margin-bottom: 20px;}
.main.fieldbox { margin-bottom: 30px; }
.fieldbox { margin: 10px 0; }
.fieldheader p { margin: 0; background: #002948; padding: 8px; color: #fff; text-align: center; font-weight: 700; border-top-left-radius: 5px; border-top-right-radius: 10px; font-size:25px;}
.fieldheader p i.fa { margin: 0 auto!important;width: 45px;height: 20px;text-align: center; }
.fieldcontent { padding: 20px; border: 3px solid #002948; border-top: 0; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }

@import "./../../assets/css/forms.css";
</style>
